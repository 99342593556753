import getConfig from "next/config"
import Head from "next/head"
import React from "react"
import styled from "styled-components"

import { CustomError404 } from "~/components/CustomError404"
import { color, mixin } from "~/libs/style"

export type Props = {
  configDomain: { [key: string]: string }
}
export const getStaticProps = async () => {
  const {
    serverRuntimeConfig: { configDomain }
  } = getConfig()

  return { props: { configDomain } }
}

export default function Custom404({ configDomain }: Props) {
  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-title" content="イージーゲーム" />
        <meta
          name="description"
          content="人気のオンラインゲームから、簡単なカジュアルゲームまで、国内外から様々な種類をご用意。大人から子供まで、短時間でも長時間でも、無料で遊べます"
        />
        <meta name="keywords" content="無料,ゲーム,無料ゲーム,スマホゲーム,ブラウザゲーム" />
        <title>指定されたページは存在しません - イージーゲーム</title>
      </Head>
      <CustomError404 title="指定されたページは存在しないか、">
        指定されたページは存在しないか、
        <br />
        既に削除された可能性があります。
        <ButtonWrapper>
          <a href={configDomain.top}>イージーゲームTOPへ</a>
        </ButtonWrapper>
      </CustomError404>
    </>
  )
}

const ButtonWrapper = styled.div`
  ${mixin.maxWidth}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 56px;
  margin: 0 auto;
  margin-top: 72px;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: #3c3c3c;
  letter-spacing: 0.04em;
  background: ${color.white};
  border: 1px solid rgba(60, 60, 60, 0.4);
  border-radius: 10px;
`
