import React from "react"
import styled from "styled-components"

type CustomErrorProps = {
  title: string
}

export const CustomError404: React.FC<CustomErrorProps> = ({ children }) => {
  return (
    <Wrapper>
      <Header>
        <h1>イージーゲーム</h1>
      </Header>

      <Body>
        <div>{children}</div>
      </Body>

      <Footer>
        <small>©mediba</small>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

const Header = styled.header`
  background-color: #fff;
  border-bottom: 1px solid #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  font-size: 14px;
  color: #3c3c3c;
  font-weight: 700;
`

const Body = styled.div`
  margin: auto 0;
  font-size: 15px;
  line-height: 1.5;
  color: #3c3c3c;
  text-align: center;
  min-height: calc(100vh - 138px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Footer = styled.footer`
  box-sizing: border-box;
  width: 100%;
  height: 57px;
  font-size: 12px;
  text-align: center;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
`
